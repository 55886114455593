import styled from "styled-components";

const WordGrid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
`;

export default function AddWord({ state }) {
  return (
    <>
      <WordGrid>WIP.</WordGrid>
    </>
  );
}
